<template>
     <div class="flex justify-content-between w-full" :class="data.action !== 'delete' ? 'justify-content-start': 'justify-content-start'"> 
        <div class="flex-column">
            <p class="p-0 mb-1 text-600 font-semibold">Antrag Gleitzeitänderung</p>
            <h5 class="p-0 m-0">{{data.request.meta.first_name}} {{data.request.meta.last_name}}</h5>
            <ul>
                <li v-for="(item, index) in data.request.meta.details.new" :key="index">
                    {{ dateToGermanDateStr(new Date(item.start_date_time)) }}: {{ dateToTimeStr(new Date(item.start_date_time)) }} - {{ dateToTimeStr(new Date(item.end_date_time)) }} Uhr
                </li>
            </ul>
        </div>
        <div class="flex w-6 align-items-center" :class="detailsButtonArrangementClass">
            <i v-if="userRole === 'admin'" class="pi pi-info-circle message-icon mr-3" @click="showDetails"/>
            <i v-badge="numMessages" class="pi pi-envelope message-icon" @click="toggle"/>
        </div>

        <OverlayPanel ref="op" class="border-round-3xl p-shiftify-chat">
            <div class="overlay-panel">
                <Chat 
                :employeeId="userId" 
                :messages="messages" 
                :requestId="data.request.id"
                @updateParent="updateComponent"
                />
            </div> 
        </OverlayPanel>
    </div>

</template>


<script>
import {dateToTimeStr, isoDateTimeStrToIsoDateStr, dateToGermanDateStr} from '../../../js_utilities/dateUtils.js'
import Chat from '../../Chat/Chat.vue'

export default {
    data() {
        return {
            userRole: null,
            dateToTimeStr,
            dateToGermanDateStr,
            isoDateTimeStrToIsoDateStr
        }
    },

    components: {Chat},

    props: {
        data: {
            type: Object,
            required: true
        }
    },

    computed: {
        detailsButtonArrangementClass() {
            return "justify-content-end"
        },

        userId() {
            return this.$store.getters.getLoggedInUser.uuid
        },

        messages(){
            return this.data?.request?.messages ?? [];
        },
        numMessages(){
            return this.data?.request?.messages?.length.toString() ?? "0";
        },
    },

    emits: ["close", "updateParent"],


    methods: {
        toggle(event) {
            this.$refs.op.toggle(event);
        },

        updateComponent() {
            console.log("updateParent from RequestHourlyAbsenceUpdateDetails component")
            this.$emit("updateParent")
        },

        showDetails(){
            let department_id = this.data.request?.requester_employee?.department?.id || null;
            
            if (department_id !== null){
                let path = `/holidayplanner/${this.data.request.meta.details.new[0].start_date_time}/${this.data.request.meta.details.new[0].end_date_time}/${department_id}`
                console.log(path)
                this.$router.push({ 
                    path: path, 
                });
            } else {
                let path = `/holidayplanner/${this.data.request.meta.details.new[0].start_date_time}/${this.data.request.meta.details.new[0].end_date_time}`
                console.log(path)
                this.$router.push({ 
                    path: path, 
                });
            }
            this.$emit("close")

        }
    },

    created(){
        this.userRole = this.$store.getters.getLoggedInUser.role.name
    }
}
</script>


<style scoped>
.label-text {
    font-size: 1rem !important;
  }

.button-height{
    height: 1.5rem;
}

.p-button{
    color: rgba(60, 55, 220, 1.0) !important;
    background-color: rgba(60, 55, 220, 0.05) !important;
}

.p-button:hover{
    color: rgba(60, 55, 220, 1.0) !important;
    background-color: rgba(60, 55, 220, 0.25) !important;
}

.overlay-panel {
    width: 50vw;
    height: 50vh;
}


.message-icon{
    font-size: 1.5rem;
    transition: font-size 0.15s ease-in-out;
}

.message-icon:hover{
    font-size: 1.75rem
}
</style>