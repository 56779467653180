<template>
  <!-- The outer wrapper applies different styles based on whether the message is the user's own -->
  <div class="flex flex-column">
        <div :class="messageClass">
            <div class="message-content">
            {{ message.content }}
            </div>
        </div>
        <div :class="messageMetaClass">
            <!-- Display sender's full name -->
            <span>
                <span v-if="!isOwn">{{ message.sender.firstName }} {{ message.sender.lastName }} </span> <span>{{ formattedDate }} Uhr</span>
            </span>
        </div>
  </div>
  

</template>

<script>
export default {
  name: 'Message',
  props: {
    message: {
      type: Object,
      required: true
    },
    isOwn: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formattedDate() {
      // Format the created_at date to German locale, timezone Europe/Berlin,
      // with short date and time styles.
      return new Date(this.message.created_at).toLocaleString('de-DE', {
        timeZone: 'Europe/Berlin',
        dateStyle: 'short',
        timeStyle: 'short'
      });
    },
    messageClass() {
      return {
        'border-round-2xl': true,
        'shadow-2': true,
        'p-3': true,
        'mb-2': true,
        // Apply own-message class to shift the message to the right
        'own-message': this.isOwn,
        'other-message': !this.isOwn
      };
    },
    messageMetaClass() {
      return {
        'message-meta-left': !this.isOwn,
        'message-meta-right': this.isOwn
      };
    }
  }
};
</script>

<style scoped>
.own-message {
  background-color: #004d70; /* light blue background for own messages */
  margin-left: auto;
  max-width: 75%;
  color: #ffffff; 
}

.other-message {
  background-color: #ffffff; /* light grey background for messages from others */
  margin-right: auto;
  max-width: 75%;
}

.message-meta-left {
    padding-left: 0.3rem;
    color: rgb(110, 110, 110);
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: flex-start;
    font-size: 0.9rem;
}

.message-meta-right {
    padding-right: 0.3rem;
    color: rgb(110, 110, 110);
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: flex-end;
    font-size: 0.9rem;
}

.message-content {
  font-size: 1.15rem;
}
</style>
