<template>   
    <div class="flex justify-content-center flex pt-4 w-full">
        <div class="flex flex-column" :class="{ 'w-12 md:w-10 lg:w-9 xl:w-7': this.mode === 'notifications', 'w-12 md:w-11 lg:w-10 xl:w-7': this.mode === 'requests' }">
            <div class="flex justify-content-start w-full bottom-border sticky-header pt-3">
                <SelectButton v-if="options !== null" v-model="showReadNotifications" :options="options" optionLabel="name" optionValue="value" aria-labelledby="basic"/>
            </div>
            <DisplayNotificationsBase 
            :isReadMode="showReadNotifications"
            :data="notificationData"
            @notificationHandeled="handleNotificationHandeled"
            @close="handleClose"
            />
        </div>
    </div>
</template>


<script>
import DisplayNotificationsBase from './DisplayNotificationsBase.vue'

export default {
    components: {
        DisplayNotificationsBase,
    },
    emits: ["notificationUpdate", "close"],
    data() {
        return {
            filterName: "",
            showReadNotifications: false,
            options: null
        }
    },
    props: {
        mode: {
            type: String,
            required: true
        }
    },

    computed: {

        notificationData() {
            return this.showReadNotifications ? this.readNotifications : this.unreadNotifications;
        },

        readNotifications() {
            return this.$store.getters.getReadNotifications.filter(item => item.type === this.filterName);
        },
        unreadNotifications() {
            return this.$store.getters.getUnreadNotifications.filter(item => item.type === this.filterName);
        },

    },

    methods: {
        handleClose() {
            this.$emit("close")
        },

        handleNotificationHandeled() {
            this.$emit("notificationUpdate")
        }
    },

    created() {
        console.log("Notifications mode: ", this.mode)
        if (this.mode === "notifications") {
            this.filterName = "update"
        }

        if (this.mode === "requests") {
            this.filterName = "request"
        }

    },

    mounted() {
        if (this.mode === "notifications") {
            this.options = [{name: "Neue Benachrichtigungen", value: false}, {name: "Gelesene Benachrichtigungen", value: true}]
        }
        if (this.mode === "requests") {
            this.options = [{name: "Neue Anfragen", value: false}, {name: "Bearbeitete Anfragen", value: true}]
        }
    }
}
</script>

<style>
.p-selectbutton .p-button{
    background-color: white !important;
    padding: 1rem 2rem !important;
    color: rgb(131, 131, 131) !important;
    border-radius: 0px !important;
    border-left: 0px !important;
    border-top: 0px !important;
    border-right: 0px !important;
    border-bottom: 3px !important;

    transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.p-selectbutton .p-button.p-highlight{
    border-bottom: 3px solid !important;
    color: rgba(60, 55, 220, 1.0) !important;
    border-color: rgba(60, 55, 220, 1.0) !important;
    background-color: rgba(60, 55, 220, 0.05) !important;
    border-radius: 0.5rem 0.5rem 0 0 !important;
}

.bottom-border{
    border-bottom: 1px solid !important;
    border-color: rgba(216, 216, 216, 0.75) !important;
}

.sticky-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
}
</style>