export function areDatesSame(date1, date2) {
    // Expects two date objects
    return date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate();
}


export function dateToIsoDateStr(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}


export function dateToIsoDateTimeStr(date) {
    // formats a date object to ISO 8061 format without timezone
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}


export function dateStrToIsoDate(dateStr) {
    const [year, month, day] = dateStr.split('-');
    const paddedDateStr = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    const date = new Date(paddedDateStr);   
    return date
}

export function germanDashSeparatedDateToIsoDateTime(dateStr) {
    const [day, month, year] = dateStr.split('-');
    const paddedDateStr = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    const date = new Date(paddedDateStr);   
    return date
}


export function germanDotSeparatedDateToIsoDateTime(dateStr) {
    const [day, month, year] = dateStr.split('.');
    const paddedDateStr = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    const date = new Date(paddedDateStr);   
    return date
}

export function isInDates(someDate, arrayOfDates) {
    const someDateString = dateToIsoDateStr(someDate);
    return arrayOfDates.some(date => dateToIsoDateStr(date) === someDateString);
}


export function dateToTimeStr(date) {
    // formats a date object to ISO 8061 format without timezone
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
}


export function dateToGermanDateStr(date) {
    // formats a date object to ISO 8061 format without timezone
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${day}-${month}-${year}`;
}

export function dateToGermanDateStrAndDow(date) {
    const daysOfWeek = ["So.", "Mo.", "Di.", "Mi.", "Do.", "Fr.", "Sa."];

    // Get day of week index
    const dayOfWeek = date.getDay();  // Sunday - 0, Monday - 1, ..., Saturday - 6

    // formats a date object to ISO 8061 format without timezone
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${daysOfWeek[dayOfWeek]} ${day}-${month}-${year}`;
}

export function isoDateTimeStrToDisplayDateTime(isoDateTimeStr){
    const dateObj = new Date(isoDateTimeStr);
    
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = dateObj.getFullYear();

    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');

  return `${day}.${month}.${year} ${hours}:${minutes}`;
}


export function isoDateTimeStrToIsoDateStr(isoDateTimeStr){
    const dateObj = new Date(isoDateTimeStr);
    
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = dateObj.getFullYear();

  return `${year}-${month}-${day}`;
}

export function adjustDate(date, days) {
    let newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
}


export function getWeekBoundaryDates(date) {  
  // Reset the time part, to make the comparison easier later on
  date.setHours(0, 0, 0, 0);

  // Calculate the Sunday of the week
  const sunday = new Date(date);
  sunday.setDate(sunday.getDate() - sunday.getDay());

  // Calculate the Monday of the week
  const monday = new Date(sunday);
  monday.setDate(monday.getDate() + 1);
  return { sunday, monday };
}

export function getDayOfWeek(date) {
    let day = date.getDay() - 1; // Adjust so that Monday is 0, Tuesday is 1, etc.
    if (day < 0) day += 7; // Wrap around if the day is Sunday (-1)
    return day;
}


export function dowToDayName(dow) {
    let map = {
        0: "Mo",
        1: "Di",
        2: "Mi",
        3: "Do",
        4: "Fr",
        5: "Sa",
        6: "So"
    }
    return map[dow]
}

export function monthToMonthName(month) {
    let map = {
        0: "Januar",
        1: "Februar",
        2: "März",
        3: "April",
        4: "Mai",
        5: "Juni",
        6: "Juli",
        7: "August",
        8: "September",
        9: "Oktober",
        10: "November",
        11: "Dezember",
    }
    return map[month]
}


export function dateToPrettyGermanFormat(date) {
    const daysOfWeek = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];

    const day = date.getDate();       // Day of the month (1-31)
    const weekDay = date.getDay();
    const month = date.getMonth(); // Month (0-11) -> Add 1 to get (1-12)
    const year = date.getFullYear();  // Year (4 digits)

    const dayName = daysOfWeek[weekDay]
    const monthName = monthToMonthName(month)

    return `${dayName} ${day}. ${monthName} ${year}`
}


Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

export function getDateRange(startDate, stopDate){

    
    const startDateMidnight = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    const stopDateMidnight = new Date(stopDate.getFullYear(), stopDate.getMonth(), stopDate.getDate());

    var dateArray = new Array();
    var currentDate = startDateMidnight;
    while (currentDate <= stopDateMidnight) {
        dateArray.push(new Date(currentDate));
        currentDate = currentDate.addDays(1);
    }
    return dateArray;
}


export function compareDateRanges(oldDateRange, newDateRange) {
    // Convert date ranges into arrays of dates
    let oldDates = getDateRange(oldDateRange[0], oldDateRange[1]);
    let newDates = getDateRange(newDateRange[0], newDateRange[1]);

    // Convert arrays of dates into strings for easy comparison
    let oldDatesStr = oldDates.map(date => dateToIsoDateStr(date));
    let newDatesStr = newDates.map(date => dateToIsoDateStr(date));
    
    // Find dates that are new in newDateRange (in newDateRange but not in oldDateRange)
    let newInNewDateRange = newDatesStr.filter(date => !oldDatesStr.includes(date)).map(dateStr => new Date(dateStr));

    // Find dates that are not part of newDateRange but in oldDateRange
    let notInNewDateRange = oldDatesStr.filter(date => !newDatesStr.includes(date)).map(dateStr => new Date(dateStr));


    return {
        newInNewDateRange: newInNewDateRange,
        notInNewDateRange: notInNewDateRange
    };
}

export function isWeekend(date) {
    const day = date.getDay();
    return day === 0 || day === 6;
}

export function dateToHhMm(date) {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
}


export function parseTimeRange(timeRange) {
    // Split the string on " - "
    const parts = timeRange.split(" - ");
    
    // Ensure we have exactly two parts
    if (parts.length === 2) {
      const startTime = parts[0].trim();
      const endTime = parts[1].trim();
      
      return { start: startTime, end: endTime };
    } else {
      // Handle error or unexpected format
      console.error("Invalid time range format:", timeRange);
      return null;
    }
}


export function createIsoDateString(day, month, year) {
    // Ensure day, month, and year are integers
    day = parseInt(day, 10);
    month = parseInt(month, 10);
    year = parseInt(year, 10);
  
    // Validate the inputs
    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      throw new Error("Invalid input: day, month, and year must be integers.");
    }
    if (month < 1 || month > 12) {
      throw new Error("Invalid month: must be between 1 and 12.");
    }
    if (day < 1 || day > 31) {
      throw new Error("Invalid day: must be between 1 and 31.");
    }
    if (year < 0) {
      throw new Error("Invalid year: must be a positive integer.");
    }
  
    // Format month and day to ensure they are two digits
    const formattedMonth = month.toString().padStart(2, '0');
    const formattedDay = day.toString().padStart(2, '0');
  
    // Create ISO date string
    const isoDateString = `${year}-${formattedMonth}-${formattedDay}`;
  
    return isoDateString;
  }

export function getPreviousMonday(date) {
    const givenDate = new Date(date);
    
    // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeek = givenDate.getDay();
    
    // Calculate the date of the previous Monday
    const previousMonday = new Date(givenDate);
    previousMonday.setDate(givenDate.getDate() - (dayOfWeek + 6) % 7 - 7);
    
    return previousMonday;
  }
  
export function getFollowingSunday(date) {
    const givenDate = new Date(date);
    
    // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeek = givenDate.getDay();
    
    // Calculate the date of the following Sunday
    const followingSunday = new Date(givenDate);
    followingSunday.setDate(givenDate.getDate() + (7 - dayOfWeek) % 7 + 7);
    
    return followingSunday;
  }
