<template>
    <div class="flex justify-content-between w-full"> 
        <div class="flex-column">
            <p class="p-0 mb-1 text-600 font-semibold">{{absenceTypeDescription}} <span>{{ absenceAction }}</span></p>
            <h5 class="p-0 m-0">{{data.request.meta.first_name}} {{data.request.meta.last_name}}</h5>
            <p class="p-0 m-0">Von: <span class="line-through">{{dateToGermanDateStr(new Date(data.request.meta.details.old.start_date))}}</span> {{dateToGermanDateStr(new Date(data.request.meta.details.new.start_date))}}</p>
            <p class="p-0 m-0">Bis: <span class="line-through">{{dateToGermanDateStr(new Date(data.request.meta.details.old.end_date))}}</span> {{dateToGermanDateStr(new Date(data.request.meta.details.new.end_date))}}</p>
        </div>
        <div class="flex w-6 align-items-center" :class="detailsButtonArrangementClass">
            <i v-if="userRole === 'admin'" class="pi pi-info-circle message-icon mr-3" @click="showDetails"/>
            <i v-badge="numMessages" class="pi pi-envelope message-icon" @click="toggle"/>
        </div>

        <OverlayPanel ref="op" class="border-round-3xl p-shiftify-chat">
            <div class="overlay-panel">
                <Chat 
                :employeeId="userId" 
                :messages="messages" 
                :requestId="data.request.id"
                @updateParent="updateComponent"
                />
            </div> 
        </OverlayPanel>
    </div>

    
</template>

<script>
import {dateToGermanDateStr} from '../../../js_utilities/dateUtils.js';
import Chat from '../../Chat/Chat.vue'

export default {
    data() {
        return {
            userRole: null,
            dateToGermanDateStr
        }
    },

    props: {
        data: {
            type: Object,
            required: true
        }
    },

    components: {Chat},

    emits: ["close", "updateParent"],

    methods: {
        toggle(event) {
            this.$refs.op.toggle(event);
        },

        germanDateStrFromIsoDateStr(isoDateStr) {
            return dateToGermanDateStr(new(Date(isoDateStr)))
        },

        showDetails(){
            let department_id = this.data.request?.requester_employee?.department?.id || null;
            
            if (department_id !== null){
                this.$router.push({ 
                    path: `/holidayplanner/${this.data.request.meta.details.new.start_date}/${this.data.request.meta.details.new.end_date}/${department_id}`, 
                });
            } else {
                this.$router.push({ 
                    path: `/holidayplanner/${this.data.request.meta.details.new.start_date}/${this.data.request.meta.details.new.end_date}`, 
                });
            }
            this.$emit("close")
        },

        updateComponent() {
            console.log("updateParent from RequestDailyAbsenceUpdateDetails component")
            this.$emit("updateParent")
        },
    },

    computed: {
        detailsButtonArrangementClass() {
            return "justify-content-end"
        },

        userId() {
            return this.$store.getters.getLoggedInUser.uuid
        },

        messages(){
            return this.data?.request?.messages ?? [];
        },
        numMessages(){
            return this.data?.request?.messages?.length.toString() ?? "0";
        },

        absenceTypeDescription(){
            if (this.data.request.meta.absence_type  === null || this.data.request.meta.absence_type  === undefined) {
                // return "Abwesenheit"

                return "Abwesenheit"
            } else {
                return this.data.request.meta.absence_type.name
            }
        },

        absenceAction(){
            if (this.data.request.action == "delete") {
                return "(Löschung)"
            } else if (this.data.request.action == "update") {
                return "(Änderung)"
            } else {
                return ""
            }
        }
    },

    created(){
        this.userRole = this.$store.getters.getLoggedInUser.role.name
    }
}
</script>

<style scoped>
.label-text {
    font-size: 1rem !important;
  }

.button-height{
    height: 1.5rem;
}

.p-button{
    color: rgba(60, 55, 220, 1.0) !important;
    background-color: rgba(60, 55, 220, 0.05) !important;
}

.p-button:hover{
    color: rgba(60, 55, 220, 1.0) !important;
    background-color: rgba(60, 55, 220, 0.25) !important;
}

.overlay-panel {
    width: 50vw;
    height: 50vh;
}

.message-icon{
    font-size: 1.5rem;
    transition: font-size 0.15s ease-in-out;
}

.message-icon:hover{
    font-size: 1.75rem
}

</style>
