import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import store from './store/store';

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('./components/UserLogin.vue'),
        alias: '/'
    },
    {
        path: '/app',
        name: 'app',
        component: App,
        children: [
            {
                path: '/changepassword',
                name: 'changepassword',
                component: () => import('./components/PasswordChanger.vue'),
                alias: '/'
            },
            {
                path: '/landingpage',
                name: 'landingpage',
                component: () => import('./components/LandingPages/LandingPage.vue')
            },
            {
                path: '/profileinformation',
                name: 'profileinformation',
                component: () => import('./components/EmployeeInformation/MyProfile.vue'),
            },
            {
                path: '/requests',
                name: 'requests',
                component: () => import('./components/RequestsDisplayer/RequestsDisplayer.vue'),
            },
            {
                path: '/employeetable',
                name: 'employeetable',
                component: () => import('./components/EmployeeTable/EmployeeTable.vue')
            },
            {
                path: '/attendancecheck',
                name: 'attendancecheck',
                component: () => import('./components/AttendanceCheck/AttendanceCheck.vue')
            },

            {
                path: '/dashboardcompanyeview',
                name: 'dashboardcompanyeview',
                component: () => import('./components/AdminDashboard/CompanyView.vue')
            },

            {
                path: '/additionalfieldsadder',
                name: 'additionalfieldsadder',
                component: () => import('./components/AdditionalFields/AdditionalFieldsAdder.vue'),
                children: [
                    {
                        path: '/additionalfieldsadder/qualificationsadder',
                        component: () => import('./components/AdditionalFields/QualificationAdder.vue'),
                        alias: '/additionalfieldsadder'
                    },
                    {
                        path: '/additionalfieldsadder/teamsadder',
                        component: () => import('./components/AdditionalFields/TeamAdder.vue')
                    },
                    {
                        path: '/additionalfieldsadder/functionsadder',
                        component: () => import('./components/AdditionalFields/TeamFunctionAdder.vue')
                    },
                    {
                        path: '/additionalfieldsadder/departmentadder',
                        component: () => import('./components/AdditionalFields/DepartmentAdder.vue')
                    },
                    {
                        path: '/additionalfieldsadder/locationadder',
                        component: () => import('./components/AdditionalFields/LocationAdder.vue')
                    },
                ],
            },
            {
                path: '/absencetypeadder',
                name: 'absencetypeadder',
                component: () => import('./components/AbsenceTypes/AbsenceTypes.vue'),
            },
            {
                path: '/carryovermodelsconfig',
                name: 'carryovermodelsconfig',
                component: () => import('./components/AdditionalFields/CarryOverModelAdder.vue'),
            },
            {
                path: '/overtimeconfig',
                name: 'overtimeconfig',
                component: () => import('./components/AdditionalFields/OvertimeModelsAdder.vue'),
            },
            {
                path: '/requestapprovalmodels',
                name: 'requestapprovalmodels',
                component: () => import('./components/RequestApprovalModels/RequestApprovalModels.vue'),
            },
            {
                path: '/shiftrhythmadder',
                name: 'shiftrhythmadder',
                component: () => import('./components/ShiftRhythm/ShiftrhythmAdder.vue')
            },
            {
                path: '/notimplemntedyet',
                name: 'notimplemntedyet',
                component: () => import('./components/NotImplementedYet.vue')
            },
            {
                path: '/holidayplanner',
                name: 'holidayplanner',
                component: () => import('./components/HolidayPlanning/HolidayPlanner.vue'),
                children: [
                    {
                        path: ':startDate/:endDate/:department_id?',
                        component: () => import('./components/HolidayPlanning/HolidayPlanner.vue'),
                        name: 'holidayplannerwithargs',
                    }
                ]
            },
            {
                path: '/reports',
                name: 'reports',
                component: () => import('./components/Reports/Reports.vue')
            },
            // {
            //     path: '/employeetimetracking',
            //     name: 'employeetimetracking',
            //     component: () => import('./components/TimeTracking/EmployeeTimeTracker.vue')
            // },
        ]
    },
];

const router = createRouter({
    // history: createWebHashHistory(),
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    // Exclude the login route from the check.
    if (to.name !== 'login') {
        // If the user is not authenticated, redirect to the login page.
        if (!store.getters.getIsAuthenticated) {
        next({ name: 'login' });
        } else {
        next(); // The user is authenticated so they can proceed to the next route.
        }
    } else {
        next(); // This is the login route, so just proceed.
    }
})


export default router;
