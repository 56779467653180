<template>
  <div class="chat-container flex flex-column justify-content-between" style="height: 100%;">
    <!-- Message list container with vertical scrolling -->
    <div class="messages p-flex-grow-1" style="overflow-y: auto;">
      <Message
        v-for="msg in sortedMessages"
        :key="msg.id"
        :message="msg"
        :isOwn="msg.sender_id === employeeId"
      />
    </div>
    <!-- New message input field and send button -->
    <div class="new-message flex align-items-center justify-content-center border-round-2xl bg-white">
      <InputText
        v-model="newMessage"
        placeholder="Type your message..."
        class="mr-3 border-none"
        style="flex: 1;"
      />
      <Button label="senden" @click="sendMessage" class="p-button-text text-lg" size="large"/>
    </div>
  </div>
</template>

<script>
import Message from './Message.vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

export default {
  name: 'Chat',
  components: {
    Message,
    InputText,
    Button
  },
  props: {
    employeeId: {
      type: String,
      required: true
    },
    messages: {
      type: Array,
      default: () => []
    },
    requestId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      userId: null,
      newMessage: ''
    };
  },
  computed: {
    sortedMessages() {
      // Use the messages prop directly and sort them.
      return this.messages.slice().sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    }
  },

  emits: ["updateParent"],

  methods: {
    sendMessage() {
      if (this.newMessage.trim()) {
        const now = new Date();
        let payload = {
          request_id: this.requestId,
          sender_id: this.userId,
          content: this.newMessage,
          created_at: now
        };

        console.log("sendMessage")
        console.log(payload)

        let token = this.$store.getters.getJwtToken;
        let url = process.env.VUE_APP_BASE_URL + `requests/${this.requestId}/message`;
        const requestOptions = {
          method: "POST",
          headers: { 
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}` 
          },
          body: JSON.stringify(payload)
        };

        fetch(url, requestOptions)
          .then(response => {
            if (!response.ok) {
              throw new Error(`Request failed with status ${response.status}`);
            }
            return response.json();
          })
          .then(() => {
            // Dispatch the update so that parent components and the store are aware of changes.
            this.$store.dispatch('updateNotificationsFromDb', this.userId);
            // Clear the input field. The messages list should update reactively.
            this.newMessage = '';

            console.log("updateParent from Chat component")
            this.$emit("updateParent")
          })
          .catch(error => {
            console.error("An error occurred:", error);
          });
      }
    }
  },
  created() {
    this.userId = this.$store.getters.getLoggedInUser.uuid;
  }
};
</script>

<style scoped>
.chat-container {
  width: 100%;
  height: 100%;
}

.messages {
  padding: 1rem;
}

.new-message {
  padding: 1rem;
}

.border-none:focus {
  border: none !important;
  box-shadow: none !important;
}

/* Hide scrollbar but allow scrolling */
.messages {
  overflow: auto;
  scrollbar-width: none;
}

.messages::-webkit-scrollbar {
  display: none;
}
</style>
